@import 'normalize.css';

input {
  font-variant-numeric: lining-nums;
}

/* For Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

/* Webkit browsers like Safari and Chrome */
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
