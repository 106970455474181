@charset "utf-8";

@font-face {
  font-family: 'Raleway';
  src: url('./fonts/Raleway-Thin.ttf');
  font-weight: 100;
}

@font-face {
  font-family: 'Raleway';
  src: url('./fonts/Raleway-ExtraLight.ttf');
  font-weight: 200;
}

@font-face {
  font-family: 'Raleway';
  src: url('./fonts/Raleway-Light.ttf');
  font-weight: 300;
}

@font-face {
  font-family: 'Raleway';
  src: url('./fonts/Raleway-Regular.ttf');
  font-weight: 400;
}

@font-face {
  font-family: 'Raleway';
  src: url('./fonts/Raleway-Medium.ttf');
  font-weight: 500;
}

@font-face {
  font-family: 'Raleway';
  src: url('./fonts/Raleway-SemiBold.ttf');
  font-weight: 600;
}

@font-face {
  font-family: 'Raleway';
  src: url('./fonts/Raleway-Bold.ttf');
  font-weight: 700;
}

@font-face {
  font-family: 'Raleway';
  src: url('./fonts/Raleway-ExtraBold.ttf');
  font-weight: 800;
}
